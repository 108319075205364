import React, {useEffect} from "react"

import Layout from "../components/Layout/Layout"
import Seo from "../components/Layout/Seo"
import { PageTitle } from '../components/Components/Headings/PageTitle';



const NotFoundPage = () => {


  return (
    <Layout>
      <Seo title="Kinzica Studio - Privacy Policy" />
      <PageTitle title={"Page not found"} subtitle="Sorry for the inconvenient... try some other page"/>

      
    </Layout >

  )
}

export default NotFoundPage;


